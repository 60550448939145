html{
  scroll-behavior: smooth;
}
body{
  background: black;
  color: white;
}

svg{
  cursor: pointer;
  background-color: transparent;
}
